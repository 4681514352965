import * as React from "react"
import Icon, { IconProps} from "./Icon"

export default class IconAndroid extends Icon {
  constructor(props: IconProps) {
    super(props)
    this.name="Android";
    this.IconSVG = (
        <svg version="1.1" id="Capa_1" x="0px" y="0px"
        viewBox="0 0 505.145 505.145">
   <g>
       <g>
           <g>
               <path d="M68.541,164.715h-1.294c-16.588,0-30.113,13.568-30.113,30.113v131.107
                   c0,16.61,13.525,30.134,30.113,30.134h1.316c16.588,0,30.113-13.568,30.113-30.134V194.827
                   C98.654,178.283,85.108,164.715,68.541,164.715z"/>
               <path d="M113.085,376.54c0,15.229,12.446,27.632,27.675,27.632h29.574v70.817
                   c0,16.631,13.568,30.156,30.113,30.156h1.294c16.61,0,30.156-13.546,30.156-30.156v-70.817h41.33v70.817
                   c0,16.631,13.611,30.156,30.156,30.156h1.273c16.609,0,30.134-13.546,30.134-30.156v-70.817h29.595
                   c15.207,0,27.654-12.403,27.654-27.632V169.525H113.085V376.54z"/>
               <path d="M322.041,43.983l23.491-36.26c1.51-2.287,0.841-5.414-1.467-6.903
                   c-2.286-1.51-5.414-0.884-6.903,1.467l-24.353,37.512c-18.27-7.485-38.676-11.691-60.226-11.691
                   c-21.571,0-41.934,4.206-60.247,11.691l-24.31-37.512c-1.488-2.351-4.638-2.977-6.946-1.467
                   c-2.308,1.488-2.977,4.616-1.467,6.903l23.512,36.26c-42.387,20.773-70.968,59.924-70.968,104.834
                   c0,2.761,0.173,5.479,0.41,8.175h280.053c0.237-2.696,0.388-5.414,0.388-8.175C393.009,103.907,364.406,64.756,322.041,43.983z
                    M187.655,108.911c-7.442,0-13.482-5.997-13.482-13.46c0-7.463,6.04-13.439,13.482-13.439c7.485,0,13.482,5.975,13.482,13.439
                   S195.097,108.911,187.655,108.911z M317.49,108.911c-7.442,0-13.482-5.997-13.482-13.46c0-7.463,6.04-13.439,13.482-13.439
                   c7.463,0,13.46,5.975,13.46,13.439C330.95,102.914,324.953,108.911,317.49,108.911z"/>
               <path d="M437.876,164.715h-1.251c-16.588,0-30.156,13.568-30.156,30.113v131.107
                   c0,16.61,13.59,30.134,30.156,30.134h1.273c16.609,0,30.113-13.568,30.113-30.134V194.827
                   C468.011,178.283,454.464,164.715,437.876,164.715z"/>
           </g>
       </g>
       <g>
       </g>
       <g>
       </g>
       <g>
       </g>
       <g>
       </g>
       <g>
       </g>
       <g>
       </g>
       <g>
       </g>
       <g>
       </g>
       <g>
       </g>
       <g>
       </g>
       <g>
       </g>
       <g>
       </g>
       <g>
       </g>
       <g>
       </g>
       <g>
       </g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   </svg>
    );
  }
}
