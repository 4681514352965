import * as React from "react"
import Icon, { IconProps } from "./Icon"

export default class IconMagicLeap extends Icon {
  constructor(props: IconProps) {
    super(props)
    this.name = "Magic Leap: AR glasses"
    this.IconSVG = (
      <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 50 50">
        <g id="MiyS8B.tif">
          <g>
            <path
              d="M49.8,33.5c-1.2-0.4-2.3-0.7-3.5-1c-1.5-0.4-2.9-0.7-4.4-1.1c-0.6-0.2-0.8,0.1-1.1,0.6c-2.6,5.6-7,9-13.1,10
			c-7.7,1.3-15.3-2.9-18.4-10c-0.3-0.6-0.6-0.8-1.2-0.6c-2.3,0.6-4.7,1.2-7,1.9c-0.2,0.1-0.5,0.1-0.9,0.3c0.6-1.6,1.1-3,1.7-4.3
			C3.3,26,5.4,23.3,8,20.9c0.3-0.2,0.4-0.6,0.6-0.9c2.3-7.4,8.9-12.2,16.7-12.2c7.4,0,14.1,5,16.2,12c0.2,0.5,0.5,1,0.8,1.3
			c3.4,3.2,5.8,7,7.2,11.5C49.6,32.8,49.7,33.1,49.8,33.5z M38,19.6c0-1.4-0.5-2.8-1.4-3.7c-2.1-2.2-5.2-1.6-6.2,1.2
			c-0.7,2,0.2,4.7,2,5.9c0.7,0.5,1.5,0.8,2.4,0.7C36.6,23.5,37.9,21.8,38,19.6z M20.1,18.4c-0.1-0.4-0.2-1-0.4-1.6
			c-0.8-1.9-2.8-2.6-4.7-1.8c-2.5,1.2-3.6,4.8-2.1,7.1c1.1,1.7,3.1,2.1,4.8,1C19.3,22.1,20,20.5,20.1,18.4z"
            />
          </g>
        </g>
      </svg>
    )
  }
}
