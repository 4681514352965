import * as React from "react"
import Icon, { IconProps } from "./Icon"

export default class IconPugJS extends Icon {
  constructor(props: IconProps) {
    super(props)
    this.name = "PugJS"
    this.IconSVG = (
      <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 128 128">
        <path
          style={{fill: '#6a89cc'}}
          d="M107.2,52.4c-0.2-4.4,0.2-8.3-1.7-11.6c-4.8-8.2-16.7-13-41-13V28l0,0v-0.3C41,27.7,28,32.6,23.3,40.8
	c-1.9,3.3-1.7,7.2-1.9,11.6c-0.2,3.5-1.8,7.2-1.1,11.2c0.8,5.2,1.2,10.4,1.9,15.2c0.6,3.9,5.9,7.2,6.4,10.9
	c1.4,10.2,11.7,14.9,35.9,14.9v0.1l0,0v-0.1c24.5,0,34.3-4.7,35.7-14.9c0.5-3.8,5.6-7,6.2-10.9c0.8-4.8,1.1-10,1.9-15.2
	C108.9,59.6,107.4,55.8,107.2,52.4z"
        />
        <circle cx="92" cy="59.6" r="8.8" />
        <circle style={{fill: '#6a89cc'}} cx="89.8" cy="55.7" r="2.3" />
        <path
          style={{fill: '#6a89cc'}}
          d="M78.7,59.2c0,0,7.9,5.4,12.3,10.7c4.3,5.3,4.2,6.3,4.2,6.3l-3.1,1.4c0,0-4.4-8.3-9.8-11.4s-6.1-5.7-6.1-5.7
	L78.7,59.2z"
        />
        <path
          d="M87.9,72.9c-4.9-6-12.6-11.4-23.2-11.4h-0.1c-10.7,0-18.4,5.5-23.2,11.4s-4.6,8.5-4.6,14.3c0,21,7.4,14.8,12.3,17.3
	s10.2,1.5,15.5,1.5h0.1c5.4,0,10.5,0.9,15.5-1.6s12.3,3.8,12.3-17.2C92.5,81.4,92.9,78.9,87.9,72.9z"
        />
        <path
          style={{fill: '#6a89cc'}}
          d="M64.8,66.6c0-0.4,3.4-0.5,5.2-1.7c0,0-3.7,1.2-4.5,0.7c-0.8-0.4-1-1.6-1-1.6s-0.3,1.2-0.9,1.6
	c-0.7,0.4-4.9-0.7-4.9-0.7s5.6,1.4,5.6,1.7s-0.1,1.3-0.1,2c0,2.5,0,8.7,0.4,9.2c0.6,0.9,0.4-6.7,0.4-9.2
	C64.8,67.9,64.8,67.1,64.8,66.6z"
        />
        <path
          style={{fill: '#6a89cc'}}
          d="M64.6,78.3c2.7,0,11.1,5.8,11.2,12.9c0-0.1,0-0.2,0-0.4c0-7.3-6.8-13.3-11.2-13.3s-11.2,6-11.2,13.3
	c0,0.1,0,0.2,0,0.4C53.6,84,62,78.3,64.6,78.3z"
        />
        <circle cx="36.9" cy="59.6" r="8.8" />
        <circle style={{fill: '#6a89cc'}} cx="39.2" cy="55.7" r="2.3" />
        <path
          style={{fill: '#6a89cc'}}
          d="M49.8,59.2c0,0-7.9,5.4-12.2,10.7s-4.2,6.3-4.2,6.3l3.1,1.4c0,0,4.4-8.3,9.8-11.4s6.1-5.7,6.1-5.7L49.8,59.2z"
        />
        <path className="st1" d="M64.5,47.1" />
        <path className="st1" d="M86.7,42.6l0.4,0.1L86.7,42.6z" />
        <path className="st1" d="M86.6,42.6L86.6,42.6z" />
        <path
          d="M89.1,30.4c0,0,11.6,9.7,14.9,20.9s2,24.8,4.6,26.5c3.7,2.4,7.9-11.8,9.3-13.4c2.2-2.4,9.5-8.5,10-9.6
	c0.5-1.1-14.7-17.8-21.4-21C98.5,29.9,88.5,29.5,89.1,30.4z"
        />
        <path
          d="M38.9,30.4c0,0-11.6,9.7-15,20.9s-2,24.8-4.6,26.5C15.6,80.3,11.4,66,10,64.4C7.9,62,0.5,55.9,0,54.8s14.8-17.8,21.5-21
	C29.5,29.9,39.5,29.5,38.9,30.4z"
        />
        <circle style={{fill: '#6a89cc'}} cx="54.3" cy="71.9" r="0.8" />
        <circle style={{fill: '#6a89cc'}} cx="49.7" cy="76.8" r="0.8" />
        <circle style={{fill: '#6a89cc'}} cx="48.1" cy="71.9" r="0.8" />
        <circle style={{fill: '#6a89cc'}}cx="73.7" cy="71.9" r="0.8" />
        <circle style={{fill: '#6a89cc'}} cx="78.3" cy="76.8" r="0.8" />
        <circle style={{fill: '#6a89cc'}} cx="79.9" cy="71.9" r="0.8" />
        <path d="M56.5,64.4c-1-2.3,2.6-6,8.3-6s9.3,3.8,8.3,6c-1,2.3-4.7,3.1-8.3,3.2S57.5,66.7,56.5,64.4z" />
        <ellipse
          transform="matrix(0.9683 -0.2497 0.2497 0.9683 -13.5983 18.5953)"
          style={{fill: '#6a89cc'}}
          cx="66.5"
          cy="62.9"
          rx="0.8"
          ry="1.5"
        />
        <ellipse
          transform="matrix(0.2962 -0.9551 0.9551 0.2962 -16.4196 103.5917)"
          style={{fill: '#6a89cc'}}
          cx="62.1"
          cy="62.9"
          rx="1.5"
          ry="0.8"
        />
      </svg>
    )
  }
}
