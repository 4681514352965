import * as React from "react"
import Icon, { IconProps} from "./Icon"

export default class IconHoloLens extends Icon {
  constructor(props: IconProps) {
    super(props)
    this.name="HoloLens";
    this.IconSVG = (
        <svg version="1.1" x="0px" y="0px" viewBox="0 0 1000 1000">
        <g><g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)"><path d="M4090.8,2232.6c-1263.3-65.1-2350.5-279.5-3008.9-593.4c-229.7-109.1-222-80.4-191.4-694.8C903.9,665,923,427.6,932.6,418.1c11.5-11.5,90,1.9,210.5,38.3C2029.4,720.5,3041.9,887,4167.4,954c484.3,28.7,1693.9,11.5,2153.3-28.7c924.5-84.2,1755.2-225.9,2478.7-423c199.1-55.5,250.7-63.2,269.9-44c13.4,17.2,28.7,191.4,42.1,509.1c19.1,421.1,19.1,493.8-5.7,539.8c-68.9,134-767.5,382.8-1464.3,522.5C6726.5,2215.4,5323.5,2295.8,4090.8,2232.6z"/><path d="M435,1273.6c-181.8-128.2-268-202.9-300.5-254.6C103.8,967.4,100,915.7,100,544.4c0-333.1,5.7-419.2,26.8-426.8c32.5-13.4,411.5,137.8,457.5,181.8c32.5,32.5,32.5,53.6,3.8,497.7c-15.3,254.6-28.7,476.6-28.7,491.9C559.4,1327.2,502,1321.5,435,1273.6z"/><path d="M9440.6,1294.7c0-11.5-13.4-231.6-30.6-490c-28.7-451.7-28.7-472.8,5.7-505.3c44-44,424.9-195.2,457.5-181.8c21.1,7.6,26.8,93.8,26.8,423c0,465.1-1.9,476.6-137.8,585.7C9566.9,1285.1,9440.6,1352.1,9440.6,1294.7z"/><path d="M4150.2,643.9C3267.8,594.1,2215,427.6,1468.6,222.8C1009.2,98.4,992,90.8,984.3,27.6c-7.7-70.8,80.4-495.8,143.6-692.9c223.9-702.5,872.8-1150.4,1881.5-1299.6c476.6-70.8,1098.7-90,1221.2-40.2c84.2,36.4,187.6,132.1,380.9,357.9c156.9,187.6,277.5,269.9,386.6,269.9c132.1,0,195.2-49.8,518.7-405.8c208.6-231.6,246.9-250.8,535.9-250.8c463.2,0,983.8,59.3,1349.4,153.1c769.5,199.1,1276.7,616.3,1464.3,1202c80.4,246.9,176.1,727.3,153.1,769.5c-11.5,21.1-105.3,55.5-271.8,101.4c-869,239.3-1872,394.3-2924.7,453.6C5438.3,666.9,4540.6,666.9,4150.2,643.9z"/><path d="M9335.4-27.9c-5.7-13.4-36.4-153.1-68.9-312c-32.5-157-65.1-306.3-68.9-329.2c-15.3-65.1,47.9-57.4,235.4,30.6c237.4,111,468.9,356,417.3,438.3C9829.2-167.6,9440.6-6.9,9377.5-5C9358.3-5,9339.2-16.4,9335.4-27.9z"/><path d="M396.7-125.5c-105.3-44-202.9-88-216.3-95.7c-45.9-30.6-21.1-90,86.1-204.8c84.2-91.9,145.5-135.9,283.3-202.9c199.1-95.7,268-107.2,254.6-40.2c-128.2,608.7-134,625.9-181.8,624C603.4-45.1,502-83.4,396.7-125.5z"/></g></g>
        </svg>
    );
  }
}
