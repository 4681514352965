import * as React from "react"
import Icon, { IconProps} from "./Icon"

export default class IconVBNet extends Icon {
  constructor(props: IconProps) {
    super(props)
    this.name="Visual Basic .net";
    this.IconSVG = (
        <svg version="1.1" id="Layer_1" x="0px" y="0px"
        viewBox="0 0 50 50">
   <g>
       <path d="M0.2,8.7c0.1,0,0.2,0,0.3,0c1.7,0,3.3,0,5.1,0c1.2,4.2,2.5,8.4,3.8,12.8c0.1-0.3,0.2-0.5,0.2-0.7
           c1.1-3.9,2.3-7.7,3.4-11.6c0.1-0.4,0.3-0.5,0.6-0.5c4.6,0,9.2,0,13.9,0c0.9,0,1.8,0.1,2.6,0.4c1.8,0.6,2.6,2,2.7,3.9
           c0.1,1.7-0.8,3.1-2.4,3.9c-0.1,0-0.1,0.1-0.2,0.1c0.5,0.2,1.1,0.4,1.5,0.7c1.5,1,1.9,2.5,1.8,4.1c-0.1,2-1.5,3.8-3.2,4.1
           c-1.1,0.2-2.2,0.4-3.4,0.4c-2.9,0.1-5.8,0-8.6,0c-0.1,0-0.2,0-0.3,0c0-5.5,0-10.9,0-16.4c0,0-0.1,0-0.1,0c-0.1,0.1-0.1,0.2-0.2,0.4
           c-1.8,5.2-3.6,10.4-5.4,15.5c-0.1,0.4-0.3,0.5-0.7,0.5c-1.6,0-3.1,0-4.7,0c-0.3,0-0.5-0.1-0.6-0.4c-2-5.6-4-11.3-6-16.9
           C0.3,9,0.2,8.9,0.2,8.7z M23.3,22.7c1.2-0.1,2.5,0,3.7-0.2c0.9-0.1,1.4-0.8,1.4-1.7c0-0.8-0.4-1.5-1.2-1.6
           c-1.3-0.2-2.5-0.2-3.8-0.2c0,1.2,0,2.3,0,3.3C23.2,22.4,23.2,22.5,23.3,22.7z M23.2,15.6c0.1,0,0.1,0,0.1,0c0.6,0,1.2,0,1.8,0
           c0.5,0,0.9,0,1.4-0.1c0.8-0.2,1.1-0.8,1.1-1.7c0-0.8-0.5-1.4-1.2-1.4c-1-0.1-2.1-0.1-3.2-0.2C23.2,13.4,23.2,14.5,23.2,15.6z"/>
       <path d="M42.2,37.3c-0.5,1.8-1.5,3.1-3.2,3.7c-2.2,0.8-4.4,0.7-6.4-0.8c-1.1-0.8-1.7-1.9-2-3.2
           c-0.4-1.6-0.3-3.1,0.1-4.7c0.7-2.7,2.9-4.3,5.7-4.3c2.8,0,5.5,0,8.3,0c0.2,0,0.4,0,0.6,0c0-0.9,0-1.8,0-2.7c0.7,0,1.4,0,2.2,0
           c0,0.9,0,1.8,0,2.7c0.7,0,1.4,0,2.1,0c0,0.6,0,1.1,0,1.7c-0.7,0-1.4,0-2.1,0c0,0.2,0,0.3,0,0.4c0,2.6,0,5.3,0,7.9
           c0,1.2,0.4,1.5,1.6,1.4c0.2,0,0.4,0,0.6,0c0.1,0.6,0.2,1.3,0.3,1.9c-1,0.2-2,0.3-3,0c-1.1-0.3-1.6-1.1-1.6-2.4c0-2.8,0-5.7,0-8.5
           c0-0.2,0-0.4,0-0.6c-1.6,0-3.1,0-4.7,0c1.4,1.6,1.7,3.5,1.6,5.5c-3.2,0-6.4,0-9.5,0c0,3.1,2.4,5,5,4.2c1-0.3,1.6-1,2-2
           c0.1-0.4,0.3-0.5,0.7-0.4C41,37.2,41.6,37.3,42.2,37.3z M40,33.5c0-2.2-1.5-3.7-3.5-3.7c-2,0-3.6,1.7-3.6,3.7
           C35.2,33.5,37.6,33.5,40,33.5z"/>
       <path d="M20.6,30c0.5-0.5,1-1,1.5-1.3c1.8-1,3.7-0.9,5.5,0.2c1,0.7,1.4,1.8,1.4,3c0.1,2.8,0.1,5.6,0.1,8.3
           c0,0.3,0,0.6,0,1c-0.7,0-1.4,0-2.2,0c0-0.2,0-0.4,0-0.6c0-2.5,0-5.1,0-7.6c0,0,0-0.1,0-0.1c0-1.2-0.3-2.2-1.6-2.7
           c-1.2-0.4-2.8-0.1-3.6,0.9c-0.6,0.7-0.8,1.6-0.8,2.5c0,2.4,0,4.8,0,7.1c0,0.2,0,0.4,0,0.6c-0.7,0-1.4,0-2.1,0c0-4.3,0-8.5,0-12.8
           c0.6,0,1.2,0,1.9,0C20.6,28.9,20.6,29.4,20.6,30z"/>
       <path d="M15.5,38.6c0.8,0,1.4,0.7,1.4,1.5c0,0.8-0.7,1.4-1.4,1.4c-0.8,0-1.4-0.7-1.4-1.5
           C14.1,39.2,14.7,38.6,15.5,38.6z"/>
   </g>
   </svg>
    );
  }
}
